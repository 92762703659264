import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import axios from 'axios';

import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { CgNotes } from "react-icons/cg";

import Chart from 'react-apexcharts'

const ScanDetails = () => {

  const location = useLocation();

  const [toast, addToast] = useState(0)
  const navigate = useNavigate()
  const [scanId, setScanId] = useState('')
  const [userId, setUserId] = useState('')
  const [dastScan, setDastScan] = useState(null)
  const [onLoading, setOnLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentVulnerability, setCurrentVulnerability] = React.useState(null);

  const [exportingPDF, setExportingPDF] = useState(false);

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '20%',
      width: '60%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow',
      zIndex:1000,
    },
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };


  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {

    window.scrollTo(0, 0);

    setOnLoading(true);

    var arr = location.search.split('=');

    var theScanId = arr[1];

    setScanId(theScanId);

    loadScanDetails(theScanId);

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadScanDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/dastScans/getDastScanDetails/'+theScanId, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setDastScan(response.data.dastScan);

    setOnLoading(false);

  };



  const downloadPDF = async (e) => {

    e.preventDefault();

    const urlToOpen = global.reportAPIURL + scanId + '/' + '6470b994fba2e5d2cff40e0c';
    window.open(urlToOpen, '_blank');

  }

  const openRemediationModal = async (value) => {

    setCurrentVulnerability(value);

    setModalIsOpen(true);
  };

  const closeModal = async () => {

    setModalIsOpen(false);
  };

  const goBack = async () => {

    navigate('/scans')
  }

  const columns = [
    "#",
    "Target URL",
    "Description",
    {
      label: "Severity",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {

          let bgColor;
          let theColor;

          if (value == 'CRITICAL') {

            bgColor = '#FF0000';
            theColor = '#FF0000';

          } else if (value == 'HIGH') {

            bgColor = '#A6001B';
            theColor = '#fff';


          } else if (value == 'MEDIUM') {

            bgColor = '#FFC300';
            theColor = 'black';

          } else if (value == 'LOW') {

            bgColor = '#B3FFB3';
            theColor = '#000';

          } 

          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <text style={{
                padding: 5, backgroundColor: bgColor, color: theColor, width: 120,
                textAlign: 'center', borderRadius: 10, fontSize: 13
              }}>{value}</text>

            </div>
          )
        }
      }
    },
    {
      label: "OWASP",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          

          if(value!=='---'){

          return (
            <div style={{
              display: "flex",
              alignItems: "center",
              flexDirection:'column',
            }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin:5, backgroundColor: '#fff', color: '#000', fontWeight:'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}         
              

            </div>
          )
                }else{
                  return (
                    <div style={{
                      display: "flex",
                      alignItems: "center"
                    }} >
        
                        ---     
                      
        
                    </div>
                  )
                }


        }
      }
    },
    {
      label: "CWE",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          

          if(value!=='---'){

          return (
            <div style={{
              display: "flex",
              alignItems: "center",
              flexDirection:'column',
            }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin:5, backgroundColor: '#fff', color: '#000', fontWeight:'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}         
              

            </div>
          )
                }else{
                  return (
                    <div style={{
                      display: "flex",
                      alignItems: "center"
                    }} >
        
                        ---    
                      
        
                    </div>
                  )
                }


        }
      }
    },
    {
      label: "REMEDIATIONS",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <CButton color="primary" variant="outline"
                onClick={() => openRemediationModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>View Remediations
              </CButton>

            </div>
          )
        }
      }
    },

  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },

    }
  })


  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: false,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: 'No vulnerabilities found',
      }
    }
  };


  var tableData = [];

  if (dastScan && dastScan.vulnerabilities) {

    for (var i = 0; i < dastScan.vulnerabilities.length; i++) {

      var vuln = dastScan.vulnerabilities[i];

      var dataItem = [];

      dataItem.push(i + 1);


      dataItem.push(vuln.targetURL);

      dataItem.push(vuln.description);

      dataItem.push(vuln.severity);
    
      if(vuln.scanRule.owasp && vuln.scanRule.owasp.length>0){
        dataItem.push(vuln.scanRule.owasp);
      }else{
        dataItem.push('---');
      }

      if(vuln.scanRule.cwe && vuln.scanRule.cwe.length > 0){
        dataItem.push(vuln.scanRule.cwe);
      }
      else{
        dataItem.push('---');
      }

      

      dataItem.push(vuln); // for remediations      

      tableData.push(dataItem);
      
    }
  }


  var lowCount = 0;
  var mediumCount = 0;
  var highCount = 0;
  var criticalCount = 0;  


  if (dastScan) {

    for (var i = 0; i < dastScan.vulnerabilities.length; i++) {     

        if(dastScan.vulnerabilities[i].severity == 'CRITICAL'){

          criticalCount++;

        }else if(dastScan.vulnerabilities[i].severity == 'HIGH'){

          highCount++;

        }else if(dastScan.vulnerabilities[i].severity == 'MEDIUM'){

          mediumCount++;

        }else if(dastScan.vulnerabilities[i].severity == 'LOW'){

          lowCount++;
        } 
          
    }
  }

  var dataArray = [lowCount, mediumCount, highCount, criticalCount  ];

  var labelsArray = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];  
  
  const bgColorArray = [
    '#FF0000','#A6001B', '#FFC300',  '#B3FFB3', 
  ];


  const vulnDistrochartOptions = {
    labels: labelsArray,
    colors: bgColorArray,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
    },

  };

  const vulnDistroChartSeries = dataArray;

  ///Vuln type chart
  var owaspTop10Count = 0;
  var sans25Count = 0;
  var othersCount = 0;  

  var sans25CWEs = ['CWE-787', 'CWE-79', 'CWE-89', 'CWE-416', 'CWE-78', 'CWE-20', 'CWE-125', 'CWE-22', 'CWE-352', 'CWE-434', 'CWE-862', 'CWE-476', 'CWE-287', 
  'CWE-190', 'CWE-502', 'CWE-77', 'CWE-119', 'CWE-798', 'CWE-918', 'CWE-306', 'CWE-362', 'CWE-269', 'CWE-94', 'CWE-863', 'CWE-276'];


  if (dastScan) {

    for (var i = 0; i < dastScan.vulnerabilities.length; i++) {

      if (dastScan.vulnerabilities[i].owasp && dastScan.vulnerabilities[i].owasp.length > 0) {
        owaspTop10Count = owaspTop10Count + dastScan.vulnerabilities[i].owasp.length;
      }   
      
      if (dastScan.vulnerabilities[i].cwe && dastScan.vulnerabilities[i].cwe.length > 0) {

        // Check if any sans25CWE is present in the whole cwe value
        const hasMatchingCWE = sans25CWEs.some(cwe => dastScan.vulnerabilities[i].cwe.includes(cwe));

        // Increment the count if there is a matching CWE
        if (hasMatchingCWE) {
            sans25Count++;
        }
      }else{
        othersCount++;
      }


    }
  }

  

  var dataArray1 = [owaspTop10Count, sans25Count, othersCount ];

  var labelsArray1 = ['OWASP Top 10', 'SANS 25', 'Others'];  
  
  const bgColorArray1 = [
    '#00BFFF', '#9370DB', '#008080',
  ];


  const vulnTypesChartOptions = {
    labels: labelsArray1,
    colors: bgColorArray1,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
    },

  };

  const vulnTypesChartSeries = dataArray1;


  console.log('onLoading:',onLoading)

  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX:'hidden' }}>

      <>

        {onLoading ?

          <div style={{
            width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: '2%'
          }}>

            <ShimmerTable row={5} col={10} />
          </div>

          :

          <div style={{
            width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>

            <div style={{ marginBottom: '2rem', }}>
              <h2>{dastScan.projectName}</h2>
              <hr />

              <table >

                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan ID </h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {scanId}
                  </td>
                </tr>

                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Application Name </h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                  {dastScan.application.name}

                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>URLS Scanned</h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                   {dastScan.urls.length}

                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Vulnerabilities</h6>

                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                  {dastScan.vulnerabilities.length}

                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Started At</h6>

                  </td>
                  <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>

                    {(new Date(dastScan.createdAt)).toLocaleDateString('en-US')} - {(new Date(dastScan.createdAt)).toLocaleTimeString('en-US')}

                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', width: 400, background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Completed At</h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', width: 400 }}>

                    {(new Date(dastScan.scanCompletedAt)).toLocaleDateString('en-US')} - {(new Date(dastScan.scanCompletedAt)).toLocaleTimeString('en-US')}

                  </td>
                </tr>

              </table>


            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#ffffff',
                  borderWidth: 1,
                  color: '#ffffff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Scans List
              </CButton>

              <CButton
                onClick={downloadPDF}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  marginTop: 10,
                  background: '#e50202'
                }}
                color="primary"
                className="px-3"
              >

                {exportingPDF ?
                  <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                  :
                  <>
                    <BiExport size={25} style={{ color: '#ffffff', marginRight: 10 }} />
                    Export to PDF
                  </>
                }

              </CButton>
            </div>
          </div>

        }



       

        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



          <h4 style={{ marginTop: 30 }}>Severity Scoring</h4>
          <hr />

          <span><text style={{ fontWeight: 'bold', backgroundColor:'#FF0000', color: '#fff', padding:5, borderRadius:5, marginRight:20 }}>CRITICAL</text> Vulnerabilities that can be exploited remotely, leading to immediate and widespread impact on the confidentiality,
            integrity, and availability of systems or data.</span>

          <span style={{ marginTop: 15 }}><text style={{ fontWeight: 'bold', backgroundColor:'#A6001B', color: '#fff', padding:5, borderRadius:5, marginRight:20  }}>HIGH</text> Vulnerabilities that can be exploited but require some form of user interaction or other
            preconditions to be met, potentially resulting in significant impact on system or data.</span>

          <span style={{ marginTop: 15 }}><text style={{ fontWeight: 'bold', backgroundColor:'#FFC300', color: '#000', padding:5, borderRadius:5, marginRight:20  }}>MEDIUM</text> Vulnerabilities that could result in a compromise of system or data security,
            but require more complex exploitation techniques or have limited impact.</span>

          <span style={{ marginTop: 15 }}><span style={{ fontWeight: 'bold', backgroundColor:'green', color: '#fff', padding:5, borderRadius:5, marginRight:20   }}>LOW</span> Vulnerabilities that have a low likelihood of being exploited or have minimal impact on system or data security.</span>



          
          

        </div>



        {onLoading == true ?

<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 50 }}>

<ShimmerTable row={8} col={10} />


</div>
:

<>


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 50, height: 500 }}>


            <div style={{
                  width: '30%', marginLeft: '10%', marginRight: '10%', display: 'flex', flexDirection: 'column',
                  marginTop: '2%',background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
            }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Vulnerability Distribution</h4>
                  <hr />

                  <Chart options={vulnDistrochartOptions} series={vulnDistroChartSeries} type="pie" />

            </div>

            <div style={{
                width: '30%', marginLeft: '10%', marginRight: '10%', display: 'flex', flexDirection: 'column',
                marginTop: '2%',background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>

             <h4 style={{ color: '#000', textAlign: 'center' }}>Issue Types</h4>
             <hr />

            <Chart options={vulnTypesChartOptions} series={vulnTypesChartSeries} type="donut" />


          </div>


      </div>
  



     
  



  </>

}




        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%'
        }}>

          {onLoading &&
            <ShimmerTable row={8} col={10} />

          }


          {!onLoading &&

            <>

              {tableData.length > 0 &&

                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    style={{ height: "57vh" }}
                    data={tableData}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              }

            </>


          }

        </div>


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Remediations"
        >

          <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeModal} >
            <AiFillCloseCircle size={30} color={'#000'} />
          </button>

          {currentVulnerability &&

            <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>


              <h5 style={{ color: '#000' }}><strong>Issue</strong>: {currentVulnerability.message}</h5>
              <h5 style={{ color: '#000' }}><strong>Severity</strong>: {currentVulnerability.impact}</h5>

              <hr />

              <h5 style={{ color: '#000' }}>REMEDIATIONS</h5>
              <hr />

                <div style={{ backgroundColor: '#ebedef', padding: 10, marginTop: 10, borderRadius: 15 }}>

                  <h5 style={{ color: '#000', fontSize: 16, fontWeight: 'normal' }}>{currentVulnerability.fix}</h5>

                </div>
             

            </div>
          }


        </Modal>

      </>

    </div>
  )
}

export default ScanDetails



