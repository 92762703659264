import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sideBarHandler } from 'src/store/sideBar/actions'
import { Link } from "react-router-dom";

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'

import { AppSidebarNav } from './AppSidebarNav'
import logo from '../assets/images/intrucept-dark-logo.png'
import lightLogo from '../assets/images/intrucept-dark-logo.png'

import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import './style.css'
import { AiFillDashboard } from "react-icons/ai";
import { MdOutlineApps } from "react-icons/md";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdIntegrationInstructions } from "react-icons/md";
import { SlOrganization } from "react-icons/sl";
import { SiHelpdesk } from "react-icons/si";

import { useLocation } from 'react-router-dom'

// sidebar nav config
import { _nav1, _nav2, _nav3 } from '../_nav';


const AppSidebar = (props) => {

  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidbarReducer.sidebarShow)
  const sideBarSpec = useSelector((state) => state.loginInfo.loginInfo.user?.roles);
  const [user, setUser] = useState(null);
  const [isActiveRoute, setIsActiveRoute] = useState(false);
  const [currentRoute, setCurrentRoute] = useState('/user-dashboard');

  const location = useLocation();

  const localData = JSON.parse(localStorage.getItem("user"));

  const authUser = sideBarSpec || localData?.user.userType;
  

  const handleSidebar = (e) => {
    //console.log('comes to handle sidebar')
    // dispatch(sideBarHandler(!e))
  }  

  useEffect(() => {

    setCurrentRoute(location.pathname);
    getUserDetails();

  }, [])

  const highLightMenu = (theRoute) => {

    setCurrentRoute(theRoute);
  }


  const getUserDetails = () => {

    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  }


  return (

    <CSidebar style={{ background: "#252b3b", color: "#000000", width: '15%' }}
      position="fixed"
      unfoldable={unfoldable}
      visible={true}
      onVisibleChange={(visible) => {
        handleSidebar(!visible)
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/" >
      <img src={lightLogo} style={{ width: 200, alignSelf: 'center' }} alt="" />        


      </CSidebarBrand>      

      <CSidebarNav>

        <CContainer fluid >

          {user && user.userType == 'user' &&

            <div style={{ width: '100%', marginLeft: '0%', display: 'flex', marginTop:'5%',
            flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

              <Link to="/user-dashboard" onClick={() => highLightMenu('/user-dashboard')}

                className="menuLink"
                style={{
                  padding: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                  background: currentRoute == '/user-dashboard' ? '#e50202' : '#252B3B', justifyContent: 'flex-start'
                }}>

                <AiFillDashboard size={22} color={currentRoute == '/user-dashboard' ? '#fff' : '#fff'} />
                <span className="headerText" style={{ marginLeft: 10, color: currentRoute == '/user-dashboard' ? '#252B3B' : '#fff' }}>Dashboard</span>
              </Link>


              <Link to="/applications" onClick={() => highLightMenu('/applications')}
                className="menuLink"
                style={{
                  padding: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                  background: (currentRoute == '/applications' || currentRoute == '/add-application' || currentRoute.includes('edit-application') || currentRoute.includes('application') )  ? '#e50202' : '#252B3B', justifyContent: 'flex-start'
                }}>

                <MdOutlineApps size={22} color={currentRoute == '/applications' ? '#fff' : '#fff'} />
                <span className="headerText" style={{ marginLeft: 10, color: currentRoute == '/applications' ? '#252B3B' : '#fff' }}>Applications</span>
              </Link>

              <Link to="/scans" onClick={() => highLightMenu('/scans')}
                className="menuLink"
                style={{
                  padding: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                  background: (currentRoute == '/scans' || currentRoute.includes('scan-detail') || currentRoute.includes('start-dast-scan')) ? '#e50202' : '#252B3B', justifyContent: 'flex-start'
                }}>

                <AiOutlineSecurityScan size={22} color={(currentRoute == '/scans' || currentRoute.includes('scan-detail')|| currentRoute.includes('start-dast-scan'))  ? '#fff' : '#fff'} />
                <span className="headerText" style={{ marginLeft: 10, color: (currentRoute == '/scans' || currentRoute.includes('scan-detail')|| 
                currentRoute.includes('start-dast-scan')) ? '#252B3B' : '#fff' }}>Scans</span>
              </Link>

              <Link to="/integrations" onClick={() => highLightMenu('/integrations')}
                className="menuLink"
                style={{
                  padding: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                  background: currentRoute == '/integrations' ? '#e50202' : '#252B3B', justifyContent: 'flex-start'
                }}>

                <MdIntegrationInstructions size={22} color={currentRoute == '/integrations' ? '#fff' : '#fff'} />
                <span className="headerText" style={{ marginLeft: 10, color: currentRoute == '/integrations' ? '#252B3B' : '#fff' }}>Integrations</span>
              </Link>


              <Link to="/tickets" onClick={() => highLightMenu('/tickets')}
                className="menuLink"
                style={{
                  padding: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                  background: (currentRoute.includes('ticket')) ? '#e50202' : '#252B3B', justifyContent: 'flex-start'
                }}>

                <SiHelpdesk size={22} color={currentRoute == '/tickets' ? '#fff' : '#fff'} />
                <span className="headerText" style={{ marginLeft: 10, color: currentRoute == '/tickets' ? '#252B3B' : '#fff' }}>Tickets</span>
              </Link>

              <Link to="/organization" onClick={() => highLightMenu('/organization')}
                className="menuLink"
                style={{
                  padding: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                  background: (currentRoute == '/organization'
                  || currentRoute.includes('users') || currentRoute.includes('add-user') || currentRoute.includes('edit-user')
                  || currentRoute.includes('business-unit')
                  || currentRoute.includes('asset-group') 
                  ) ? '#e50202' : '#252B3B', justifyContent: 'flex-start'
                }}>

                <SlOrganization size={22} color={currentRoute == '/organization' ? '#fff' : '#fff'} />
                <span className="headerText" style={{ marginLeft: 10, color: currentRoute == '/organization' ? '#252B3B' : '#fff' }}>Organization</span>
              </Link>


              <Link to="/users" onClick={() => highLightMenu('/users')}
                className="menuLink"
                style={{
                  padding: 5, display: 'flex', flexDirection: 'row', textDecoration: 'none', marginTop:10,
                   justifyContent: 'flex-start'
                }}>

                <text className="headerText" style={{ marginLeft: 40, fontSize:14, color: 
                  (currentRoute.includes('users') || currentRoute.includes('add-user') || currentRoute.includes('edit-user')) ? '#e50202' : '#fff' }}>
                  Users</text>
              </Link>

              <Link to="/business-units" onClick={() => highLightMenu('/business-units')}
                className="menuLink"
                style={{
                  padding: 5, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                   justifyContent: 'flex-start'
                }}>

                <text className="headerText" style={{ marginLeft: 40, fontSize:14, color: 
                  (currentRoute.includes('business-unit')) ? '#e50202' : '#fff' }}>
                  Business Units</text>
              </Link>

              <Link to="/asset-groups" onClick={() => highLightMenu('/asset-groups')}
                className="menuLink"
                style={{
                  padding: 5, display: 'flex', flexDirection: 'row', textDecoration: 'none',
                   justifyContent: 'flex-start'
                }}>

                <text className="headerText" style={{ marginLeft: 40, fontSize:14, color: 
                  (currentRoute.includes('asset-group') ? '#e50202' : '#fff') }}>
                  Asset Groups</text>
              </Link>

            </div>
          }

        </CContainer>

      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => { handleSidebar(!unfoldable) }}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)