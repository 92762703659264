import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoCodescan } from "react-icons/go";
import axios from 'axios';


const StartDASTScan = () => {

  const navigate = useNavigate()

  const [scanName, setScanName] = useState("")
  const [url, setURL] = useState("")
  
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])


  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();


  function checkURLType(url) {

    try {
      new URL(url);
    } catch (error) {
      return false; // Invalid URL
    }
    
    const extension = url.split('.').pop().toLowerCase();
    
    if (extension === 'json') {
      return true; // Valid URL to a JSON file
    } else if (extension === 'yaml' || extension === 'yml') {
      return true; // Valid URL to a YAML file
    } else {
      return false; // Valid URL, but not a JSON or YAML file
    }
  }

  

  useEffect(() => {    

    fetchApplications();   

  }, []);



  const fetchApplications = async () => {

    setLoading(true);
    
  
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/applications/getAllApplications`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    setApplications(response.data.applications);

    if(response.data.applications.length > 0){
      setApplicationId(response.data.applications[0]._id)
    }else{
      setApplicationId('');
    }
   
  
    setLoading(false);
  };



  const startScan = () => {

    if (scanName === '') {

      setValidationFailed(true);
      setErrorText('Scan name is required.');

    } if (applicationId === '') {

      setValidationFailed(true);
      setErrorText('Please select an application');

    }if (url === '') {

      setValidationFailed(true);
      setErrorText('URL is required');

    } else {

      setLoading(true)      

      const bearerToken = localStorage.getItem('ASIToken');

      
      const body = {
        "scanName":scanName,
        "applicationId":applicationId,
        "url":url
      }

      // Make the API call
      fetch(global.backendUrl+'/api/v1/dastScans/startDastScan', {
        method: 'POST',
        headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => {

        // Handle the API response

        if(data.hasOwnProperty('error')){
           setValidationFailed(true);
           setErrorText(data.error);
           setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setValidationFailed(true);
          setErrorText("Something went wrong. Please try again");
          
       }else{

           setSubmissionSuccess(true);
           setLoading(false);

           toast('Scan started', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
    

           // Move to active scans window in 1 second
           navigate('/scans')
        }

      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });


      const timer = setTimeout(() => {

         //setSubmissionSuccess();
         toast('Scan started', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });


       // Move to active scans window in 1 second
       navigate('/scans')
      }, 10000);         

    }   

  }


  const goBack = (e) => {

    e.preventDefault();
    navigate('/scans')
  }


  return (
    <div style={{ display:'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>Start a DAST Scan</h2>
            <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  marginRight:20,
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#fff',
                  borderWidth: 1,
                  color: '#fff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight:10 }} />
                Back to Scan List
              </CButton>
          </div>


          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>

         
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Scan Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Scan Name"
                autoComplete="scanName"
                className="white-input"
                onChange={(e) => setScanName(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>       

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Select application</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="scanType"
                className="white-input"
                onChange={(e) => setApplicationId(e.target.value)}
                style={{ width: '100%' }}
              >
                {applications.map(application => (
                  <option key={application._id} value={application._id}>
                    {application.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>   


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>URL</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="URL"
                autoComplete="url"
                className="white-input"
                onChange={(e) => setURL(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>                


            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={startScan}
              disabled={loading}
            >              


              {loading ?
                            <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                            :
                            'Start Scan'
                          }


            </CButton>

        {submissionSuccess &&
            <span style={{color:'green', fontSize:17}}>The scan is started. 
            After the scan is complete, the scan report is sent to the email mentioned. <br/>It will also be available 
              in the dashboard under Scans menu. You may leave this window now. 
              <br/> <br/>
              Moving to scans list in 5 seconds...
            </span>
        }
          </div>


        </div>
      </div>

    </div>
  )
}

export default StartDASTScan



