import React from 'react'

import UserDashboard from './views/users/UserDashboard';
import AllScans from './views/users/allScans';
import ScanDetail from './views/users/scanDetails';
import Applications from './views/users/applications';
import Application from './views/users/application';
import AddApplication from './views/users/addApplication';
import EditApplication from './views/users/editApplication';
import StartDASTScan from './views/users/startDASTScan';
import Integrations from './views/users/integrations';
import Organization from './views/users/organization';
import Tickets from './views/users/tickets';
import OpenTicket from './views/users/openTicket';
import Ticket from './views/users/ticket';
import Users from './views/users/users';
import AddUser from './views/users/addUser';
import EditUser from './views/users/editUser';
import AssetGroups from './views/users/assetGroups';
import AddAssetGroup from './views/users/addAssetGroup';
import EditAssetGroup from './views/users/editAssetGroup';
import BusinessUnits from './views/users/businessUnits';
import AddBusinessUnit from './views/users/addBusinessUnit';
import EditBusinessUnit from './views/users/editBusinessUnit';


const routes = [
  { path: '/user-dashboard', name: 'User Dashboard', element: UserDashboard},
  { path: '/scans', name: 'DAST Scans', element: AllScans},  
  { path: '/scan-detail', name: 'DAST Scan Detail', element: ScanDetail}, 
  { path: '/start-dast-scan', name: 'Start DAST Scan', element: StartDASTScan},   
  { path: '/applications', name: 'Applications', element: Applications},  
  { path: '/application', name: 'Application', element: Application},  
  { path: '/add-application', name: 'Add Application', element: AddApplication},  
  { path: '/edit-application', name: 'Edit Application', element: EditApplication},  

  { path: '/asset-groups', name: 'Asset Groups', element: AssetGroups},  
  { path: '/add-asset-group', name: 'Add Asset Group', element: AddAssetGroup},  
  { path: '/edit-asset-group', name: 'Edit Asset Group', element: EditAssetGroup},  

  { path: '/business-units', name: 'Business Units', element: BusinessUnits},  
  { path: '/add-business-unit', name: 'Add Business Unit', element: AddBusinessUnit},  
  { path: '/edit-business-unit', name: 'Edit Business Unit', element: EditBusinessUnit},  

  { path: '/users', name: 'Users', element: Users},  
  { path: '/add-user', name: 'Add User', element: AddUser},  
  { path: '/edit-user', name: 'Edit User', element: EditUser},  

  { path: '/integrations', name: 'Integrations', element: Integrations},  
  { path: '/organization', name: 'Organization', element: Organization},  
  { path: '/tickets', name: 'Tickets', element: Tickets}, 
  { path: '/open-ticket', name: 'Open Ticket', element: OpenTicket},  
  { path: '/ticket', name: 'Ticket', element: Ticket}, 


]

export default routes 